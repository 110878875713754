import { TFunction } from 'gatsby-plugin-react-i18next';
import moment from 'moment';

import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';

import { getPhoneNumber } from 'util/globalVariables';
import {
    CURRENT_STEP_DESCRIPTION,
    isRxExpired,
    isRxOnHold,
    isShipStatusExpired,
    shouldRxShowPrice
} from 'util/prescription';

import { PrescriptionCardProps, StatusProps } from './prescriptions-card/types';

function getSortOrderForRxSubStatusStep(currentStep?: number): number {
    const sortOrders: Record<string, number> = {
        [CURRENT_STEP_DESCRIPTION.PROCESSING]: 81,
        [CURRENT_STEP_DESCRIPTION.FILLING_IN_PHARMACY]: 82,
        [CURRENT_STEP_DESCRIPTION.PACKAGED]: 83,
        [CURRENT_STEP_DESCRIPTION.SHIPPED]: 84,
        [CURRENT_STEP_DESCRIPTION.OUT_FOR_DELIVERY]: 85,
        [CURRENT_STEP_DESCRIPTION.DELIVERED]: 86
    };

    return sortOrders[currentStep ?? CURRENT_STEP_DESCRIPTION.UNKNOWN] ?? 80;
}

export function prescriptionPayloadToProps(
    payload: PrescriptionObjectPayload,
    t: TFunction<'translation'>,
    accountHasInsurance: boolean,
    onOrderRxClick?: () => void
): PrescriptionCardProps {
    onOrderRxClick = onOrderRxClick ? onOrderRxClick : () => {};
    let orderStatus: PrescriptionCardProps['orderStatus'] = 'NO_REFILLS';
    let sortOrder = 140;

    if (payload.inOrderCart) {
        orderStatus = 'IN_CART';
        sortOrder = 10;
    } else {
        if (payload.webEligibilityStatus === 'ELIGIBLE' && payload.rxStatus === 'Profiled') {
            orderStatus = 'NEW_RX';
            sortOrder = 20;
        }
        if (payload.webEligibilityStatus === 'ELIGIBLE' && payload.rxStatus !== 'Profiled') {
            const nextFillDateDiff = moment(payload.nextFillDate).diff(moment().format('MM/DD/YYYY'), 'days');

            orderStatus = 'REFILL_AVAILABLE';
            sortOrder = 30;
            sortOrder = nextFillDateDiff < 1 ? 30 : 31;
        }
        if (payload.webEligibilityStatus === 'MDO_CANCELLED') {
            orderStatus = 'MDO_CANCELLED';
            sortOrder = 40;
        }
        if (
            payload.webEligibilityStatus === 'AUTH_REQ' &&
            !isRxExpired(payload.rxExpirationDate) &&
            Number(payload.fillsRemaining) === 0
        ) {
            orderStatus = 'OUT_OF_REFILLS';
            sortOrder = 50;
        }
        if (
            (payload.webEligibilityStatus === 'NOT_ELIGIBLE' &&
                payload.itemInWorkflow === false &&
                payload.nextFillDate !== undefined &&
                payload.lastFillDate !== undefined &&
                isShipStatusExpired(payload.lastFillDate)) ||
            // Hotfix DRX-1981.
            // When a RX is shipped, a new RX is added to the profile with the same drug.
            // The old RX is discontinued and the new RX will be the primary RX.
            //
            // During this process, the nexFillDate will be pulled to the new RX
            // but the lastFillDate not.
            //
            // In this scenario we have to check if nexFillDate is after today
            // to consider it as a Refill to Soon.
            (payload.webEligibilityStatus === 'NOT_ELIGIBLE' &&
                payload.itemInWorkflow === false &&
                payload.nextFillDate !== undefined &&
                (payload.lastFillDate === undefined || payload.lastFillDate === null) &&
                !isRxExpired(payload.nextFillDate))
        ) {
            orderStatus = 'REFILL_TOO_SOON';
            sortOrder = 60;
        }
        if (payload.webEligibilityStatus === 'PENDING') {
            orderStatus = 'PENDING';
            // show new rx requests before transfer rx requests
            sortOrder = payload.rxNumber && payload.rxNumber !== 'N/A' ? 75 : 70;
        }
        if (payload.webEligibilityStatus === 'NOT_ELIGIBLE' && payload.itemInWorkflow === true) {
            orderStatus = 'ORDERED';
            sortOrder = getSortOrderForRxSubStatusStep(payload.rxSubStatus?.CurrentStep);
        }
        if (
            payload.webEligibilityStatus === 'NOT_ELIGIBLE' &&
            payload.orderLineQueueStatus === 'SHIPPED' &&
            payload.itemInWorkflow === false &&
            !isShipStatusExpired(payload.lastFillDate)
        ) {
            orderStatus = 'SHIPPED';
            sortOrder = getSortOrderForRxSubStatusStep(payload.rxSubStatus?.CurrentStep);
        }
        if (isRxOnHold(payload)) {
            orderStatus = 'ON_HOLD';
            sortOrder = 90;
        }
        if (
            payload.rxSubStatus?.RxInProgressStatus === 0 ||
            (payload.itemInWorkflow === false && !payload.inOrderCart && payload.orderStatus === 'INCOMPLETE/PENDING')
        ) {
            orderStatus = 'INCOMPLETE/PENDING';
            sortOrder = 100;
        }
        if (payload.rxSubStatus?.CurrentStep === CURRENT_STEP_DESCRIPTION.UNKNOWN) {
            orderStatus = 'PENDING_ORDERED';
            sortOrder = 110;
        }
        // DRX-2366: We delete the !accountHasInsurance param to match the requirements
        // for the ticket, but as we couldnt have all the rxs cases covered, we will need
        // to look for another way to validate.
        if (!payload.inFormulary) {
            orderStatus = 'NOT_ON_FORMULARY';
            sortOrder = 120;
        }
        if (
            payload.webEligibilityStatus === 'AUTH_REQ' &&
            payload.inFormulary &&
            isRxExpired(payload.rxExpirationDate)
        ) {
            orderStatus = 'EXPIRED';
            sortOrder = 130;
        }
        if (payload.webEligibilityStatus === 'MDO_WAITING') {
            orderStatus = 'MDO_WAITING';
            sortOrder = 140;
        }
    }

    // push next fill date for any RX other than New, Pending or Expired
    const showNextFillDate = (props: any) => {
        if (payload.nextFillDate && !payload.inOrderCart) {
            const nextFillDateFormatted = moment(payload.nextFillDate).format('MM/DD/YYYY');
            const nextFillDateDiff = moment(payload.nextFillDate).diff(moment().format('MM/DD/YYYY'), 'days');

            props.statuses.push({
                primary: false,
                isRefillDue:
                    nextFillDateDiff < 1 &&
                    payload.webEligibilityStatus !== 'NOT_ELIGIBLE' &&
                    payload.itemInWorkflow !== true,
                status: <div>{nextFillDateFormatted}</div>,
                displayType: 'NEXT_REFILL'
            });
        }
    };

    const props = {
        fullPayload: payload, // for debugging
        prescriptionName: payload.dispensedProductName,
        orderStatus: orderStatus,
        sortOrder: sortOrder,
        inOrderCart: payload.inOrderCart,
        refillsLeft: Number(payload.fillsRemaining),
        rxNumber: payload.rxNumber,
        rxSeqNum: payload.rxSeqNum,
        autoRefillEnabled: payload.autoRefillEnabled,
        webEligibilityStatus: payload.webEligibilityStatus,
        rxExpirationDate: payload.rxExpirationDate,
        showPrice: shouldRxShowPrice(orderStatus),
        details: [
            {
                detail: t('components.prescriptionCard.rxNumberHeaderText', { number: payload.rxNumber })
            },
            {
                detail: t('components.prescriptionCard.quantity', {
                    fillQuantity: payload.fillQuantity
                })
            }
        ],
        statuses: [] as StatusProps[],
        ctas: [
            {
                label:
                    orderStatus !== 'IN_CART'
                        ? t('components.prescriptionCard.orderPrescription')
                        : t('components.prescriptionCard.inYourCart'),
                onClick: onOrderRxClick,
                disabled:
                    orderStatus !== 'NEW_RX' &&
                    orderStatus !== 'REFILL_AVAILABLE' &&
                    orderStatus !== 'NO_REFILLS' &&
                    orderStatus !== 'EXPIRED' &&
                    orderStatus !== 'OUT_OF_REFILLS',
                isMobile: false
            }
        ],
        footNote: undefined
    };
    if (orderStatus === 'IN_CART') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.inYourCart')}</div>,
            displayType: 'RX'
        });
        showNextFillDate(props);
    }
    if (orderStatus === 'NEW_RX') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderNewRxArrived')}</div>,
            displayType: 'RX'
        });
    }
    if (orderStatus === 'ORDERED') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRxOrdered')}</div>,
            displayType: 'RX'
        });
        props.statuses.push({
            primary: false,
            status: <div>{t('components.prescriptionCard.orderInProgress')}</div>,
            displayType: 'ORDER'
        });
        showNextFillDate(props);
    } else if (orderStatus === 'SHIPPED') {
        const orderStatus = !payload.lastFillDate
            ? t('components.prescriptionCard.orderProcessingText')
            : t('components.prescriptionCard.orderShippedText');

        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRxOrdered')}</div>,
            displayType: 'RX'
        });
        props.statuses.push({
            primary: false,
            status: <div>{orderStatus}</div>,
            displayType: 'ORDER'
        });
        showNextFillDate(props);
    } else if (orderStatus === 'REFILL_TOO_SOON') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRefillTooSoon')}</div>,
            displayType: 'RX'
        });
        showNextFillDate(props);
    } else if (orderStatus === 'REFILL_AVAILABLE') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderRefillAva')}</div>,
            displayType: 'RX'
        });
        showNextFillDate(props);
    } else if (orderStatus === 'NO_REFILLS') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.noRefills')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.orderPreAuthReq');
    } else if (orderStatus === 'ON_HOLD') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.rxOnHold')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.rxOnHoldFootnote', {
            phoneNumber: getPhoneNumber({ isEnd: true })
        });
    } else if (orderStatus === 'INCOMPLETE/PENDING') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderStatusPending')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.unknownStatusMessage');
    } else if (orderStatus === 'NOT_ON_FORMULARY') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.notIncludedInYourPlan')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.notOnFormularyMessage', { phoneNumber: getPhoneNumber({}) });
    } else if (orderStatus === 'PENDING_ORDERED') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderStatusPending')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.unknownStatusMessage');
    } else if (orderStatus === 'PENDING') {
        props.statuses.push({
            primary: true,
            status: (
                <div>
                    {payload.rxNumber && payload.rxNumber !== 'N/A'
                        ? t('components.prescriptionCard.transferPending')
                        : t('components.prescriptionCard.requestPending')}
                </div>
            ),
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.pendingMessage');
    } else if (orderStatus === 'MDO_CANCELLED') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderMdoCancelled')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.orderMdoCancelledMessage');
    } else if (orderStatus === 'MDO_WAITING') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderMdoWaiting')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.orderMdoWaitingMessage');
    } else if (orderStatus === 'EXPIRED') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.orderExpired')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.rxExpiredFootnote');
    } else if (orderStatus === 'OUT_OF_REFILLS') {
        props.statuses.push({
            primary: true,
            status: <div>{t('components.prescriptionCard.outOfRefills')}</div>,
            displayType: 'RX'
        });
        props.footNote = t('components.prescriptionCard.rxOutOfRefillsFootnote');
    }

    return props;
}
