// General
import { graphql, navigate, PageProps } from 'gatsby';
import { ENABLE_AUTO_REFILL } from 'gatsby-env-variables';
import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Ui-kit
import PageSection from 'ui-kit/page-section/page-section';

// Components
import AccountBalance from 'components/account-balance/account-balance.component';
import AutoRefillBanner from 'components/auto-refill-banner/auto-refill-banner.component';
import AutoRefillBody from 'components/auto-refill-body/auto-refill-body';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import DashboardCtaBlock from 'components/dashboard-cta-block/DashboardCtaBlock';
import MedicineCabinetLayout from 'components/layouts/medicine-cabinet/medicine-cabinet.layout';
import ManageFamilyBanner from 'components/manage-family-banner';
import MedicineCabinetCart from 'components/medicine-cabinet-cart/medicine-cabinet-cart';
import PrescriptionsList from 'components/prescriptions-list-v2/prescriptions-list.component';
import 'components/prescriptions-list/PrescriptionList.style.scss';

// State
import {
    accountAcknowledgementTermsStatusSelector,
    accountProfileSelector,
    accountIsCaliforniaUserSelector,
    accountAutoRefillEligibleSelector
} from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { closeNewPrescriptionModal } from 'state/medicine-cabinet/medicine-cabinet.reducers';
import {
    medicineCabinetAutoRefillPlanEligibleSelector,
    medicineCabinetPopulatedSelector,
    medicineShowNewPrescriptionModalSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';

// Utils
import { TrackNewPrescriptionNumber } from 'util/google_optimize/optimize_helper';
import storageHelper from 'util/storageHelper';
import { validateAutoRefillModalDisplay } from 'util/autorefill';

// Hooks
import { useAddTransferPrescription } from 'hooks/useAddTransferPrescription';

interface MedicineCabinetProps extends PageProps<GatsbyTypes.MedicineCabinetDataQuery> {}

// Main component
const MedicineCabinet: React.FC<MedicineCabinetProps> = ({ data }) => {
    //General
    const pillImage = data.successModalPillImage;

    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { resetForm, setPrescriptionFlowType } = useAddTransferPrescription();

    // Selectors
    const newPrescriptionModal = useSelector(medicineShowNewPrescriptionModalSelector);
    const profileObject = useSelector(accountProfileSelector);
    const displayAutoRefillBanner = useSelector(medicineCabinetAutoRefillPlanEligibleSelector);
    const hasAcceptedTerms = useSelector(accountAcknowledgementTermsStatusSelector);
    const isCAResident = useSelector(accountIsCaliforniaUserSelector);
    const isMedicineCabinetLoaded = useSelector(medicineCabinetPopulatedSelector);
    const isAutoRefillEligible = useSelector(accountAutoRefillEligibleSelector);

    // Constants
    const userName = profileObject ? profileObject.patientFirstName : '';

    const closeModalHandler = useCallback(() => {
        dispatch(closeModal({}));
        storageHelper.local.setAutoRefillToggleFlag();
    }, [dispatch]);

    const showAutoRefillModal = useCallback(() => {
        if (validateAutoRefillModalDisplay(isAutoRefillEligible, isCAResident)) {
            dispatch(
                openModal({
                    showClose: true,
                    className: 'scroll-modal',
                    onClose: closeModalHandler,
                    bodyContent: (
                        <BirdiModalContent
                            icon={'none'}
                            title={t(`pages.autoRefill.userVerification.title`)}
                            body={<AutoRefillBody isModal onClose={closeModalHandler} />}
                        />
                    ),
                    ctas: []
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAutoRefillEligible, isCAResident]);

    useEffect(() => {
        const newPrescriptionsModalContent = (prescriptions: string[]) => {
            const isMultiple = prescriptions.length > 1;
            return (
                <BirdiModalContent
                    icon={'default'}
                    title={
                        isMultiple
                            ? t('modals.newPrescriptions.title.multiplePrescriptions')
                            : t('modals.newPrescriptions.title.singlePrescription')
                    }
                    body={
                        <div className="text-left lead">
                            <p>
                                {isMultiple
                                    ? t('modals.newPrescriptions.body.multiplePrescriptions')
                                    : t('modals.newPrescriptions.body.singlePrescription')}
                            </p>
                            <ul className="font-weight-bold pl-3">
                                {prescriptions.map((rx, index) => (
                                    <li key={index}>{rx}</li>
                                ))}
                            </ul>
                            <p>{t('modals.newPrescriptions.body.orderNow')}</p>
                        </div>
                    }
                />
            );
        };
        if (hasAcceptedTerms && newPrescriptionModal.show) {
            dispatch(
                openModal({
                    showClose: true,
                    className: 'prescription-modal',
                    bodyContent: newPrescriptionsModalContent(newPrescriptionModal.prescriptions),
                    onClose: () => {
                        dispatch(closeNewPrescriptionModal());
                        showAutoRefillModal();
                    },
                    ctas: [
                        {
                            label: t('modals.newPrescriptions.labels.viewMedicineCabinet'),
                            variant: 'primary',
                            onClick: (event) => {
                                dispatch(closeModal({}));
                                dispatch(closeNewPrescriptionModal());
                                navigate('/secure/medicine-cabinet');

                                const eventTarget = event?.target as HTMLElement;
                                if (newPrescriptionModal.prescriptions.length > 0 && eventTarget) {
                                    TrackNewPrescriptionNumber(eventTarget, newPrescriptionModal.prescriptions.length);
                                }
                            },
                            dataGALocation: t('modals.newPrescriptions.title.singlePrescription').replace(/ /g, '')
                        }
                    ]
                })
            );
        }
        if (isMedicineCabinetLoaded && !newPrescriptionModal.show) {
            showAutoRefillModal();
        }
    }, [
        isMedicineCabinetLoaded,
        newPrescriptionModal,
        hasAcceptedTerms,
        profileObject,
        dispatch,
        t,
        closeModalHandler,
        showAutoRefillModal
    ]);

    return (
        <MedicineCabinetLayout greetingMessage={`${t('pages.medicineCabinet.greetingMessageDefault')} ${userName}`}>
            <PageSection>
                <Row>
                    <Col xs={12} md={12} lg={8}>
                        <DashboardCtaBlock
                            icon={'plane'}
                            title={t('components.dashboardCtaBlock.titles.transferPre')}
                            bodyText={t('components.dashboardCtaBlock.body.transferPre')}
                            ctas={[
                                {
                                    label: t('components.dashboardCtaBlock.ctas.transferPre'),
                                    variant: 'dark',
                                    dataGALocation: 'MedicineCabinet',
                                    onClick: () => {
                                        resetForm();
                                        setPrescriptionFlowType({ flowType: 'Transfer' });
                                        navigate('/secure/prescription');
                                    }
                                }
                            ]}
                        />

                        {ENABLE_AUTO_REFILL && displayAutoRefillBanner && <AutoRefillBanner />}

                        <AccountBalance variant="banner" />

                        <ManageFamilyBanner />

                        <PrescriptionsList pillImage={pillImage} />
                    </Col>
                    <Col xs={12} md={12} lg={4}>
                        <MedicineCabinetCart />
                    </Col>
                </Row>
            </PageSection>
        </MedicineCabinetLayout>
    );
};

export default MedicineCabinet;

export const query = graphql`
    query MedicineCabinetData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        successModalPillImage: file(relativePath: { eq: "assets/images/hero-pill-image-sample.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
