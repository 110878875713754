import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'ui-kit/button/button';
import InfoBox from 'ui-kit/info-box/info-box';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { AutoRefillLearnMoreModal } from 'components/auto-refill-learn-more-modal';

import './auto-refill-banner.style.scss';

const AutoRefillBanner = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleAutoRefillModal = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                ctas: [
                    {
                        label: t('modals.autoRefillLearnMore.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ],
                bodyContent: <AutoRefillLearnMoreModal t={t} />
            })
        );
    }, [dispatch, t]);

    return (
        <div className="auto-refill-banner">
            <InfoBox
                button={
                    <>
                        <Button
                            onClick={handleAutoRefillModal}
                            className="p-0 btn-demi text-cerulean d-md-none no-min-width"
                            variant="text"
                            label={t('pages.medicineCabinet.autoRefillLearnMoreLinkMobile')}
                            type="button"
                        />
                        <Button
                            onClick={handleAutoRefillModal}
                            className="p-0 btn-demi text-cerulean d-none d-md-block"
                            variant="text"
                            label={t('pages.medicineCabinet.autoRefillLearnMoreLink')}
                            type="button"
                        />
                    </>
                }
            >
                <>
                    <h4>{t('pages.medicineCabinet.autoRefillLearnTitle')}</h4>
                    <p className="d-none d-md-block">{t('pages.medicineCabinet.autoRefillLearnBody')}</p>
                </>
            </InfoBox>
        </div>
    );
};

export default AutoRefillBanner;
