// General
import React, { useEffect, useState } from 'react';
// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Ui-kit
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';

// Components
import EmptyCart from 'components/medicine-cabinet-cart/empty-cart/empty-cart.component';
import HealthProfile from 'components/medicine-cabinet-cart/health-profile/health-profile.component';
import PaymentMethod from 'components/medicine-cabinet-cart/payment-method/payment-method.component';
import PrescriptionInformation from 'components/medicine-cabinet-cart/prescription-information/prescription-information.component';
import ShippingAddress from 'components/medicine-cabinet-cart/shipping-address/shipping-address.component';
import ShippingMethod from 'components/medicine-cabinet-cart/shipping-method/shipping-method.component';
import SidebarColumn from 'components/sidebar-column/sidebar-column.component';

import {
    accountAcCodeSelector,
    accountCreditCardsSelector,
    accountHasInsuranceSelector,
    accountIsMembershipSelector,
    accountPlansSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
// States
import {
    cartItemsTotalSelector,
    cartOrderBillShipMethodSelector,
    cartOrderBillShipSelector,
    cartOrderPaymentCardSelector,
    cartSelector,
    cartSubtotalSelector
} from 'state/cart/cart.selectors';
import { drugSelector } from 'state/drug/drug.selectors';
import { medicineCabinetPrescriptionsSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

// Util
import { EXPEDITED_SHIPPING_COST, EXPEDITED_SHIPPING_ID, processCart } from 'util/cart';

import { CartTotal } from './cart-total/total-cart.component';
// Styles
import './medicine-cabinet-cart.style.scss';

// Main component
const MedicineCabinetCart: React.FC = () => {
    // Hooks
    const { t } = useTranslation();

    // Selectors
    const orderBillShip = useSelector(cartOrderBillShipSelector);
    const allPaymentData = useSelector(accountCreditCardsSelector);
    const selectedPaymentMethod = useSelector(cartOrderPaymentCardSelector);
    const cartItemsTotal = useSelector(cartItemsTotalSelector) || 0;
    const shipMethodId = useSelector(cartOrderBillShipMethodSelector);
    const estimatedSubtotal = useSelector(cartSubtotalSelector);
    const cartObject = useSelector(cartSelector);
    const prescriptionsObject = useSelector(medicineCabinetPrescriptionsSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const accountPlans = useSelector(accountPlansSelector);
    const isBirdiCash = useSelector(accountAcCodeSelector);
    const isMembership = useSelector(accountIsMembershipSelector);
    const { drugDiscountPrices } = useSelector(drugSelector);
    const profileObject = useSelector(accountProfileSelector);

    const [extendedCartObject, setExtendedCartObject] = useState<ExtendedCartObjectPayload[]>();

    useEffect(() => {
        // Since the drug prices API is called separately, we are currently validating against the prescriptions
        // already present in the cart. This ensures consistency in our approach by only fetching prescriptions
        // for the current user and refreshing the state when each tab is called. Please note:
        // This validation serves as a temporary solution, providing a safeguard against potential side effects
        // that may impact pricing results it should be reviewed for future implementations.
        async function prescriptionsInCart(cart: StartOrderV2Payload[]) {
            const prescriptionsInCart: PrescriptionObjectPayload[] = [];

            cart.forEach((orderPayload: StartOrderV2Payload) => {
                if (orderPayload.Order.refillRxs && orderPayload.Order.refillRxs.length > 0) {
                    orderPayload.Order.refillRxs.forEach((refillRx: RefillRxs) => {
                        if (refillRx.prescriptionDetail) {
                            prescriptionsInCart.push(refillRx.prescriptionDetail);
                        }
                    });
                }
            });

            return prescriptionsInCart;
        }

        async function getCartOrder() {
            if (cartObject) {
                const extendedCart = processCart(
                    cartObject,
                    accountHasInsurance,
                    profileObject?.isCaregiver ? await prescriptionsInCart(cartObject) : prescriptionsObject,
                    drugDiscountPrices,
                    accountPlans,
                    isMembership
                );
                setExtendedCartObject(extendedCart);
            }
        }
        getCartOrder();
    }, [cartObject, drugDiscountPrices, accountPlans, accountHasInsurance, isMembership, profileObject]);

    return (
        <SidebarColumn className="medicine-cabinet-v2-cart">
            <div className="medicine-cabinet-v2-cart__header">
                <CartIcon className={'header-icon-container'} />
                <h3>{t('components.medicineCabinetCart.title')}</h3>
            </div>

            {cartItemsTotal > 0 ? (
                <>
                    <ShippingAddress />
                    <ShippingMethod />
                    <PaymentMethod
                        addNewPaymentButtonLabel={t('components.medicineCabinetCart.paymentMethod.addPaymentMethod')}
                        buttonVariant="text-blue"
                        selectedPaymentMethod={selectedPaymentMethod}
                        paymentData={allPaymentData || []}
                        showSelectCardRadioInput
                        showSetDefaultLink
                        orderBillShip={orderBillShip!}
                    />
                    <HealthProfile />
                    <PrescriptionInformation
                        cartObject={extendedCartObject}
                        isInsuranceAccount={accountHasInsurance}
                        prescriptions={prescriptionsObject}
                    />
                    <CartTotal
                        cartObject={extendedCartObject}
                        prescriptionsObject={prescriptionsObject}
                        isUnknownPrice={false}
                        initialOrderPrice={String(estimatedSubtotal)}
                        currentShippingPrice={shipMethodId === EXPEDITED_SHIPPING_ID ? EXPEDITED_SHIPPING_COST : 0}
                        isInsuranceAccount={accountHasInsurance}
                        isBirdiCash={['BRD01', 'BRD02'].includes(isBirdiCash)}
                        // DRX-1660: As the new Cart has two new functionallities, i decide to create a new component
                        // to mantain the compability
                    />
                </>
            ) : (
                <EmptyCart
                    title={t('components.medicineCabinetCart.empty.title')}
                    body={t('components.medicineCabinetCart.empty.body')}
                />
            )}
        </SidebarColumn>
    );
};

export default MedicineCabinetCart;
